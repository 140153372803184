import React, { useEffect, useState } from 'react'
import axios from 'axios';

const Allexams = () =>{

    const [exams, setExams] = useState([])
    const urlExams = `${process.env.REACT_APP_url}/exams/allexams.php`
    useEffect(() => {
        axios.get(urlExams).then(res => { 
        setExams(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])
  return (
    <div className='w-full h-auto flex flex-col'>
        {
                                exams.length && exams.map((exam,index)=>
                                    <div className='w-full h-12 border-b border-gray-200 flex flex-row '>
                                        <div className='text-center w-1/4 text-sm '><span>{index}</span></div>
                                        <div className='w-1/4 text-sm '><span>{exam.subject}</span></div>
                                        <div className='w-1/4 text-sm '><span>{exam.specialite}</span></div>
                                        <div className='w-1/4 text-sm '><span>{exam.module}</span></div>
                                    </div>
                                )
                              }
    </div>
  )
}

export default Allexams