import React from 'react'
import Nav from '../components/Nav'
import LoginForm from '../components/LoginForm'

const login = () =>{
  return (
    <div className='w-full'>
        
        <LoginForm />
    </div>
  )
}

export default login