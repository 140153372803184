import React from 'react';
import { StrictMode } from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './redux/store'
import { CookiesProvider } from 'react-cookie';
ReactDom.render(
                <StrictMode>
                    <Provider store={store}>
                        <CookiesProvider>
                            <App />
                        </CookiesProvider>
                    </Provider>
                </StrictMode>
                
                , document.getElementById('root')) 