import React from 'react'
import Nav from '../components/Nav'
import MediaEX from '../components/MediaEX'
const examedia = () =>{
  return (
    <div>
        <Nav />
        <MediaEX />
    </div>
  )
}

export default examedia