import { createSlice } from '@reduxjs/toolkit'


export const qttSlice = createSlice({
    name:"quantity",
    initialState:{
        qtt: 1
    },
    reducers:{
        incrementQtt: (state)=>{
                state.qtt += 1;
                
        },
        decrementQtt: (state)=>{
            state.qtt -= 1;
        }
    }
});

export const {incrementQtt, decrementQtt } = qttSlice.actions;
export default qttSlice.reducer;