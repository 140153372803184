import React from 'react'
import Nav from '../components/Nav'
import FormationBySp from '../components/FormationBySp'

const formationsp = () =>{
  return (
    <div className='w-full'>
        <Nav />
        <FormationBySp />
    </div>
  )
}

export default formationsp