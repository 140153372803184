import React from 'react'
import CreateFormation from './CreateFormation'
import Chapter from './Chapter'

const NewFormation = () =>{
    
  return (
    <div className='w-full h-screen flex flex-col bg-white border border-gray-100 items-center px-2'>
        <div className='w-full h-20  bg-green-400 py-4 border border-gray-100 rounded-md flex flex-row items-center justify-center'>
                  <span className='font-bold text-xl'>
                     إنشاء دورة تكوينية
                  </span>
              </div>
             <CreateFormation />

             <div className='w-full h-20  bg-green-400 py-4 border border-gray-100 rounded-md flex flex-row items-center justify-center'>
                  <span className='font-bold text-xl'>
                  إضافة محور إلى الدورة التكوينية
                  </span>
              </div>
             <Chapter />
    </div>
  )
}

export default NewFormation