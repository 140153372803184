import React from 'react'
import Nav from '../components/Nav'
import SpExams from '../components/SpExams'

const examPdf = () =>{
  return (
    <div>
        <Nav />
        <SpExams /> 
    </div>
  )
}

export default examPdf