import React from 'react'
import Nav from '../components/Nav'
import FormationByModule from '../components/FormationByModule'

const modspf = () =>{
  return (
    <div className='w-full'>
        <Nav />
        <FormationByModule />
    </div>
  )
}

export default modspf