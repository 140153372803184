import Home from "../pages/home";
import AdminLog from "../pages/admin/AdminLog";
import Dashbord from "../pages/admin/Dashbord";
import { Router , Route, Routes } from 'react-router-dom'
import specialite from "../pages/admin/Specialite";
import AddExam from "../pages/admin/addExam";
import Exam from "../pages/admin/Exam";
import Formation from "../pages/admin/formation"
import Addformation from "../pages/admin/addformation"
import Specialite from "../pages/specialite";
import Pdf from "../pages/pdf";
import Tutorial from "../pages/tutorial";
import Details from "../pages/details";
import Courseview from "../pages/courseview";
import Singup from "../pages/singup";
import Login from "../pages/login";
import ExamPdf from "../pages/examPdf";
import Modsp from "../pages/modsp";
import Modspf from "../pages/modspf";
import Examedia from "../pages/examedia";
import Formationsp from "../pages/formationsp";


const AllRoutes = () =>{
    return(
        
            <Routes>
                <Route path="/" name="home" element={<Home />} />
                <Route path="/adminlog" name="adminlog" element={<AdminLog/>} />
                <Route path="/dashbord" name="dashbord" element={<Dashbord/>} />
                <Route path="/specialite" name="specialite" element={<Specialite/>} />
                <Route path="/addExam" name="addExam" element={<AddExam/>} />
                <Route path="/exam" name="exam" element={<Exam/>} />
                <Route path="/formation" name="formation" element={<Formation/>} />
                <Route path="/addformation" name="addformation" element={<Addformation/>} />
                <Route path="/sp/:id" name="specialite" element={<Specialite/>} />
                <Route path="/pdf/:id" name="pdf" element={<Pdf/>} />
                <Route path="/tutorial" name="tutorial" element={<Tutorial/>} />
                <Route path="/details/:id" name="details" element={<Details/>} />
                <Route path="/view/:id" name="view" element={<Courseview/>} />
                <Route path="/exampdf/:id" name="exampdf" element={<ExamPdf/>} />
                <Route path="/singup" name="singup" element={<Singup/>} />
                <Route path="/login" name="login" element={<Login/>} />
                <Route path="/modsp/:id/:sp" name="modsp" element={<Modsp/>} />
                <Route path="/modspf/:id/:sp" name="modspf" element={<Modspf/>} />
                <Route path="/examedia/:id" name="examedia" element={<Examedia/>} />
                <Route path="/formationsp/:id" name="formationsp" element={<Formationsp/>} />
                

            </Routes>
        
    )
}


export default AllRoutes;