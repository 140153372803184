import React from 'react'
const Banner = () =>{
  return (
    <div className='lg:w-full lg:h-screen xs:h-1/2 ' id="banner">
          <div className='w-full h-full flex justify-center items-center bg-black/[.06] bg-gradient-to-t from-gray-200 xs:py-8'>
                  <div className='w-1/2 h-auto flex items-center justify-center  '>
                        <img className="h-1/2 w-1/2" src={require('../images/banner/mathematics.svg').default} id="bounce-top"/>
                  </div>
                  <div className='w-1/2 h-auto flex flex-col text-center '>
                      <span className='lg:text-9xl xs:text-5xl font-black text-green-900'>
                              أكاديمية إميلي
                      </span>
                      <span className='text-3xl font-bold text-green-600'>
                      طريقك الى النجاح  
                      </span>
                  </div>
          </div>
    </div>
  )
}

export default Banner