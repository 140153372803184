import { createSlice } from '@reduxjs/toolkit'
let cartNumber = localStorage.getItem("cartnumber");
let incart
if(cartNumber == null){
    incart = 0;
}else{
    incart = parseInt(JSON.parse(cartNumber));
}
export const counterSlice = createSlice({
    name:"counter",
    initialState:{
        count: incart
    },
    reducers:{
        increment: (state)=>{
                state.count += 1;
                
        },
        decrement: (state)=>{
            state.count -= 1;
        }
    }
});

export const {increment, decrement } = counterSlice.actions;
export default counterSlice.reducer;