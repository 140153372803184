import React, {Fragment} from 'react'
import Nav from './components/Nav';
import AllRoutes from './conf/routes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import './style.css';
import 'video-react/dist/video-react.css'; // import css

/*
const App = () => {
  
  return (
    <div className="w-full h-48">
      <Nav />
      <AllProd />
    </div>
  )
}
*/

function App(){ 
  return(
    
    <BrowserRouter>
         <AllRoutes />
    </BrowserRouter>
           
    


  )
}

export default App

