import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Nav = () =>{
  const [specialite, setSpecialite] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const urlAPIsp = `${process.env.REACT_APP_url}/specialite/allsp.php`

 

  useEffect(() => {
    axios.get(urlAPIsp).then(res => { 
    setSpecialite(res.data)
    //setLoad(true)
    
    }).catch(err => {
    console.log(err)
    })
}, [])

const opensp = ()=>{
  let sp = document.getElementById("spmenu")
  sp.classList.toggle('hidden')
}

const openspp = ()=>{
  let spp = document.getElementById("sppmenu")
  spp.classList.toggle('hidden')
}

const toggleMenu = () => {
  setIsOpen(!isOpen);
}

const openNav = ()=>{
  let ulnav = document.getElementById("ulNAV");
  ulnav.classList.toggle('xs:hidden')
}
//{`navigation ${isOpen ? 'open' : ''}
  return (
    <div className='w-full h-24 flex flex-row items-center justify-between lg:px-12 xs:px-2 bg-white'>
        <Link onClick={() => {window.location.href=`/`}} class="flex items-center">
                  <img src="" class="h-8 mr-3" alt="" />
                  <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-green-900 ">emelycad</span>
          </Link>
          <div className='lg:w-auto xs:w-52 lg:h-auto xs:h-auto flex  lg:flex-row xs:flex-col items-center  z-50' >
            <div className="w-auto lg:flex xs:hidden" id="ulNAV">
                  <ul class="font-bold  flex  lg:flex-row xs:flex-col  p-4  items-center absolute lg:top-0 xs:top-24 lg:left-0 xs:left-8 xs:border lg:border-0 border-gray-200  bg-white">
                    
                    <li className='px-4'>
                      <Link onClick={() => {window.location.href=`/`}} class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">الرئيسية</Link>
                    </li>
                    <li className='relative inline-block px-4'  onClick={()=>opensp()}>
                      <a href="#" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">مواضيع و إمتحانات</a>
                      <div className='absolute hidden bg-white border border-gray-200 mt-2 py-2 w-32 rounded-lg shadow-md z-50' id="spmenu"> 
                          {
                            specialite.length && specialite.map((sp, index)=>
                            
                            <Link  onClick={() => {window.location.href=`/exampdf/${sp.id}`}} className="block px-4 py-2 text-gray-800 hover:bg-gray-100">{sp.label}</Link>
                            )
                          }
                      </div>
                    </li>

                    <li className='px-4 relative inline-block'  onClick={()=>openspp()}>
                      <a href="#" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">دورات تكوينية</a>
                      <div className='absolute hidden bg-white border border-gray-200 mt-2 py-2 w-32 rounded-lg shadow-md z-50' id="sppmenu"> 
                          {
                            specialite.length && specialite.map((sp, index)=>
                            <Link onClick={() => {window.location.href=`/formationsp/${sp.id}`}} class="block px-4 py-2 text-gray-800 hover:bg-gray-100">{sp.label}</Link>
                            
                            )
                          }
                      </div>
                    </li>

                    <li className='px-4'>
                      <a href="#" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">إتصل لنا</a>
                    </li>
                    <li className='flex flex-row items-center w-48 mb-2 z-40 mx-2 text-green-600 text-center  font-bold py-2 px-4 rounded shadow-md transition duration-300 transform hover:scale-105'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                     <path strokeLinecap="round" strokeLinejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                  </svg>

                      <Link  to="/login" class="block py-2 pl-3 pr-4  rounded ">تسجيل الدخول</Link>
                    </li>
                    <li className='flex flex-row items-center w-48 mb-2 z-40 mx-2  text-center text-green-600 font-bold py-2 px-4 rounded shadow-md transition duration-300 transform hover:scale-105'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      <Link to="/singup" class="py-2 pl-3 pr-4  rounded ">إنشاء حساب</Link>
                        

                    </li>
                    
                  </ul>
                </div>
          </div>
          <div className="left-2 absolute lg:hidden xs:block">
            <button class="navbar-burger flex items-center text-green-600 p-3" onClick={()=>openNav()}>
              <svg class="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
		    </div>
    </div>
  )
}

export default Nav