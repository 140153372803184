import React, { useEffect, useState } from 'react'
import axios from 'axios';

const AllFormation = () =>{
    const [formation, setFormation] = useState([])
    const urlExams = `${process.env.REACT_APP_url}/formation/allf.php`

    useEffect(() => {
        axios.get(urlExams).then(res => { 
        setFormation(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])
  return (
    <div className='w-full h-auto flex flex-col bg-white'>
        {
            formation.length && formation.map((f,index)=>
            <div className='w-full h-12 border-b border-gray-200 flex flex-row '>
                <div className='text-center w-1/4 text-sm '><span>{index + 1}</span></div>
                <div className='w-1/4 text-sm '><span>{f.name}</span></div>
                <div className='w-1/4 text-sm '><span>{f.module}</span></div>
                <div className='w-1/4 text-sm '><span>{f.spe}</span></div>
                <div className='w-1/4 text-sm '><span>{f.label}</span></div>
                <div className='w-1/4 text-sm '>
                        <span className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                            </svg>

                        </span>
                </div>
            </div>
            )
        }

    </div>
  )
}

export default AllFormation