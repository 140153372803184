import React, {Fragment, useState, useEffect } from 'react';
import {helmet } from 'react-helmet';
import {Link, redirect } from "react-router-dom";
import Nav from '../components/Nav';
import Banner from '../components/Banner';
import Modules from '../components/Modules';
import Formation from '../components/Formation';
import Specialite from '../components/Specialite';
import AllFormation from '../components/AllFormation';
import Box1 from '../components/Box1';
import Box2 from '../components/Box2';
import Box3 from '../components/Box3';
const axios = require('axios');


export default function Home() {
   
    return(
        <div className="">
            <Nav />
            <Banner /> 
            <Box1 />
            <Box2 />
            <Box3 />
            <Formation />
        </div>
            )
}