import React, { useEffect, useState } from 'react'
import axios from 'axios';

const CreateFormation = () =>{
    
    const [specialite, setSpecialite] = useState([])
    const [modules, setModules] = useState([])

    const [subject, setSubject] = useState("")
    const [spe, setSpe] = useState("")
    const [mod, setMod] = useState("")
    const [thumb, setThumb] = useState("")

    const urlAPIsp = `${process.env.REACT_APP_url}/specialite/allsp`
    useEffect(() => {
            axios.get(urlAPIsp).then(res => { 
            setSpecialite(res.data)
            //setLoad(true)
            
            }).catch(err => {
            console.log(err)
            })
        }, [])


        const urlAPImd = `${process.env.REACT_APP_url}/modules/modules`
        useEffect(() => {
              axios.get(urlAPImd).then(res => { 
              setModules(res.data)
              //setLoad(true)
              
              }).catch(err => {
              console.log(err)
              })
          }, [])

          const getimagename = (e)=>{
            setThumb(e)
          }
          //console.log(specialite)
          
          const urlAddformation = `${process.env.REACT_APP_url}/formation/newf.php?
                                                                                    s=${subject}
                                                                                    &spe=${spe}
                                                                                    &mod=${mod}
                                                                                    &thumb=${thumb}`

    const urlUpload = `${process.env.REACT_APP_url}/`
  return (
    <div className='w-full h-auto'>
         <div className='w-full h-auto bg-white border border-gray-100 rounded-md flex flex-row items-center justify-center py-4'>
              <form className='w-1/2 px-4' method="post" encType="multipart/form-data" action={urlAddformation} id="mform" target="votar">
                      <div className='w-full my-2'>
                          <label for="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">إسم الدورة</label>
                          <input
                                value={subject}
                                onChange={(e)=>setSubject(e.target.value)} 
                                type="text" id="default-input" className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          </input>
                      </div>


                      <div className='w-full my-2'>
                          <label for="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">الشعبة</label>
                          <select 
                                value={spe}
                                onChange={(e)=>setSpe(e.target.value)} 
                                id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              <option>قائمة الشعب</option>
                              {
                                specialite.length && specialite.map((sp,index)=>
                                    <option value={sp.id}>
                                      {
                                        sp.label 
                                      }
                                    </option>
                                )
                              }
                              
                          </select>
                      </div>


                      <div className='w-full my-2'>
                          <label for="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">المادة</label>
                          <select 
                          value={mod}
                          onChange={(e)=>setMod(e.target.value)} 
                          id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              <option>قائمة المواد</option>
                              {
                                modules.length && modules.map((md,index)=>
                                    <option value={md.id}>
                                      {
                                        md.module 
                                      }-{md.label}
                                    </option>
                                )
                              }
                          </select>
                      </div>

                      <div className='w-full my-2'>
                          <label for="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">صورة</label>
                          <input 
                                type="file" 
                                id="pdfthumb" 
                                name='pdfthumbnail'
                                accept='image/*'
                                onChange={(e)=>getimagename(e.target.files[0].name)} 
                                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          </input>
                      </div>




                      <button 
                            type="submit" 
                            //onClick={()=>uploadpdf()}
                            class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">أضف</button>
                   
                     
                     <iframe name="votar" className="none"></iframe>
                      </form>
              </div>
    </div>
  )
}

export default CreateFormation