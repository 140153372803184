import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton
} from 'video-react';

const View = () =>{
    const {id} = useParams();  
    const [tuto, setTuto] = useState([])
    const urlAPIFORMATION = `${process.env.REACT_APP_url}/chapter/view.php? id=${id}`
    useEffect(() => {
      axios.get(urlAPIFORMATION).then(res => { 
        setTuto(res.data)
      //setLoad(true)
      
      }).catch(err => {
      console.log(err)
      })
  }, [])
  console.log(tuto)
  return (
    <div className=''>
        {
            //src={`${process.env.REACT_APP_url}/chapter/media/${t.video}`}
            
            tuto.length && tuto.map((t,index)=>
            
            <Player className="w-auto h-auto bg-red-600">
                <source src={`${process.env.REACT_APP_url}/chapter/media/${t.video}`} />

                <ControlBar>
                  <ReplayControl seconds={10} order={1.1} />
                  <ForwardControl seconds={30} order={1.2} />
                  <CurrentTimeDisplay order={4.1} />
                  <TimeDivider order={4.2} />
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                  <VolumeMenuButton disabled />
                </ControlBar>
            </Player>
            )
        }
             
    </div>
  )
}

export default View