import React, {useEffect, useState }  from 'react'

import { useParams } from 'react-router-dom';
import axios from "axios"
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
  } from 'video-react';

  
const MediaEX = () =>{
    const {id} = useParams(); 
    const [idv, setIdv] = useState("")
    const [video, setVideo] = useState([])

    const urlAPI = `${process.env.REACT_APP_url}/exams/exam.php?id=${id}`
    useEffect(() => {
        axios.get(urlAPI).then(res => { 
        setVideo(res.data)
        }).catch(err => {
        console.log(err)
        })
    }, [])
console.log(video)
  return (
    <div className='w-full h-auto'>
        <div className='w-full h-screen bg-green-400'>
            {
                video.length && video.map((v,index)=>
                <Player>
                    <source src={`${process.env.REACT_APP_url}/exams/video/${v.videoex}`} />
                    <ControlBar>
                    <ReplayControl seconds={10} order={1.1} />
                    <ForwardControl seconds={30} order={1.2} />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                    <VolumeMenuButton disabled />
                    </ControlBar>
                </Player>
                )
            }
           
        </div>
    </div>
  )
}

export default MediaEX