import React from 'react'
import Sidebar from '../../components/admin/Sidebar'
import AddExam from '../../components/admin/AddExam'

const addExam = () =>{
  return (
    <div className='w-full h-screen flex bg-gray-100 flex-row'>
          
          <div className='w-full'> 
                <AddExam />
          </div>
          <Sidebar />
    </div>
  )
}

export default addExam