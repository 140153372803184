import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


const Formation = () =>{

    const [formation, setFormation] = useState([])

    const urlAPIsp = `${process.env.REACT_APP_url}/formation/allf`
     useEffect(() => {
        axios.get(urlAPIsp).then(res => { 
        setFormation(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])

    console.log(formation)

  return (
    <div className='my-4'>
        <div className='w-full h-auto flex flex-col bg-gray-100 min-h-screen mt-10'>
            <div className='w-full h-12 bg-white px-12 text-center '>
                    <Link >
                        <span className='font-bold text-2xl font-serif'>
                                 أهم الدورات     
                        </span>
                    </Link>
            </div>
            
            <div className='w-full h-auto flex flex-row flex-wrap justify-between overflow-hidden px-8 py-8'>
                {
                    formation.length && formation.map((f, index)=>
                            //onClick={() => {window.location.href=`/details/${fm.id}`} } 
                            <Link to={`/details/${f.idf}`}>
                                <div className='w-96 h-96 border border-gray-100 rounded-md flex flex-col overflow-hidden bg-white shadow-xl mb-10'>
                                    <div className='w-96 h-64'>
                                        <img className="h-64 w-96" src={`${process.env.REACT_APP_url}/formation/thumbnail/${f.image}`}/>
                                    </div>
                                    <div className='w-full h-auto flex flex-col justify-center items-center py-2'>
                                        <span className='text-xl font-bold my-2'>{f.name}</span>
                                        <span className='text-xl font-bold'>{f.label}-{f.module}</span>
                                    </div>
                                </div>
                            </Link>
                    )
                }

            
            </div>
        </div>
    </div>
    
  )
}

export default Formation