import React from 'react'
import DashBord from '../../components/admin/DashBord'
import Sidebar from '../../components/admin/Sidebar'
import Allexams from '../../components/admin/Allexams'
const Dashbord =() =>{
  return (
    <div className='w-full h-auto flex bg-gray-100 flex-row'>
       
        <div className='w-full bg-white flex flex-col'> 
              <div className='w-full h-16 bg-green-400 border border-gray-100 rounded-md flex flex-row items-center justify-center mb-4'>
                    <span className='font-bold text-xl'>
                          قائمة المواضيع     pdf
                    </span>
                    <a href='/addExam' class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">أضف الموضوع</a>
                </div>
                <Allexams />
          </div>
          <Sidebar />
    </div>
  ) 
}

export default Dashbord