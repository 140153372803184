import React from 'react'

const Box1 = () =>{
  return (
    <div className='w-full h-screen flex lg:flex-row xs:flex-col'>
                <div className='lg:w-1/2  xs:w-full h-full px-8 text-center py-4 flex flex-col items-center justify-center'>
                    <p className='text-3xl font-black uppercase text-green-900 mb-8'>emelycad</p>
                    <span className='text-xl font-bold'>
                        مرحبًا بك في emelycad ، وجهتك الشاملة لكل من التعلم والتقييم. تقدم منصتنا مزيجًا فريدًا من الموارد التعليمية والبرامج التعليمية التفاعلية ومواد التحضير للامتحانات ، وكلها مصممة لتمكين المتعلمين من جميع المستويات. سواء كنت طالبًا يسعى لتحقيق التميز الأكاديمي ، أو محترفًا يتطلع إلى الارتقاء بالمهارات ، أو فردًا حريصًا على استكشاف مواضيع جديدة ، فقد قمنا بتغطيتك.
                    </span>
                </div>
                <div className='lg:w-1/2 xs:w-full h-full bg-green-400' id="boxImage1">

                </div>
    </div>
  )
}

export default Box1