import React from 'react'
import Nav from '../components/Nav'
import Specialite from '../components/Specialite'
import Sujet from '../components/Sujet'

const specialite = () =>{
  return (
    <div className='w-full'>
        <Nav />
        <Specialite  />
        <Sujet />
    </div>
  )
}

export default specialite