import React from 'react'

const Box2 = () =>{
  return (
    <div className='w-full h-auto flex flex-col'>
        <div className='w-full h-24 flex items-center justify-center'>
            <p className='text-2xl font-black text-green-900 '>
                    يرافقكم مرشد  محترف في المجال     
            </p>
        </div>
    </div>
  )
}

export default Box2