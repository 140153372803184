import React, {useEffect, useState }  from 'react'
import { Link } from 'react-router-dom';
import axios from "axios"
import { useParams } from 'react-router-dom';
import ModulesBySpf from './ModulesBySpf';

const FormationByModule = () =>{
    const {id} = useParams(); 
    const {sp} = useParams(); 
    const [formation, setFormation] = useState([])

    const urlAPI = `${process.env.REACT_APP_url}/formation/formatbymodule.php?id=${id}`
    useEffect(() => { 
        axios.get(urlAPI).then(res => { 
        setFormation(res.data)
        
    }).catch(err => { 
        console.log(err)
        })
    }, [])
  return (
    <div className='w-full h-auto flex flex-row'>
        <ModulesBySpf id={sp} />

        <div className='w-4/5 h-auto min-h-screen flex flex-row flex-wrap bg-gray-100'>

                    {
                        formation.length && formation.map((p,indes)=>

                        <div 
                            onClick={() => {window.location.href=`/details/${p.idf}`}}
                            className='max-w-sm h-72 flex flex-col mx-4 my-4 overflow-hidden rounded-md border-gray-200 border drop-shadow-md bg-white cursor-pointer'>
                            <div className='w-full h-56'>
                                    <img className="h-full w-full" src={`${process.env.REACT_APP_url}/formation/thumbnail/${p.image}`}/>
                            </div>
                            <div className='flex flex-col py-2 px-2 justify-center items-center'>
                                <p className='text-2xl font-bold tracking-tight'>{p.name}</p>
                            </div>
                        
                        </div>
                        )
                    }


        </div>
    </div>
  )
}

export default FormationByModule