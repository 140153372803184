import React from 'react'
import Sidebar from '../../components/admin/Sidebar'
import NewFormation from '../../components/admin/NewFormation'

const addformation = () =>{
  return (
    <div className='w-full h-screen flex bg-gray-100 flex-row'>
        <div className='w-full h-screen flex bg-gray-100 flex-row'>
          
          <div className='w-full'> 
                <NewFormation />
          </div>
          <Sidebar />
    </div>
    </div>
  )
}

export default addformation