import React from 'react'
import Nav from '../components/Nav'
import ExamsByModule from '../components/ExamsByModule'

const modsp =() =>{
  return (
    <div className='w-full'>
        <Nav />
        <ExamsByModule />
    </div>
  )
}

export default modsp