import React from 'react'

const LoginForm = () =>{
  return (
    <div className='w-1/3 h-auto border border-gray-200 rounded-md flex flex-col py-4 px-4 bg-white'>
                
                <div className='w-full h-10 text-center'>
                    <span className='text-xs uppercase text-gray-600'>
                        connecter a votre tableau de bord
                    </span>
                </div>
                <input className='w-full h-10  rounded-md border border-gray-200 my-2' type='text'></input>
                <input className='w-full h-10  rounded-md border border-gray-200 my-2' type='password'></input>
                <button className='w-auto h-12 px-4 py-2 text-md font-bold capitalize my-2 bg-green-400 text-white'>connexion</button>
    </div>
  )
}

export default LoginForm