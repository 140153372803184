import React, {useEffect, useState }  from 'react'
import { Link } from 'react-router-dom';
import axios from "axios"
import { useParams } from 'react-router-dom';

const ModulesBySp =(props) =>{
    let id = props.id
    const [modules, setModules] = useState([])
    const urlModule = `${process.env.REACT_APP_url}/modules/modulesbysp.php?id=${id}`

    useEffect(() => { 
        axios.get(urlModule).then(res => { 
        setModules(res.data)
        
    }).catch(err => {
        console.log(err)
        })
    }, [])

    const opensidebar = ()=>{
        let sidebar  = document.getElementById('mdsp')
        sidebar.classList.toggle('hidden')
    }
  return (
    <div className='lg:w-1/5 xs:w-12  h-screen'>
        <div className='w-8 h-8 px-1 py-1 bg-green-600 border border-gray-300 lg:hidden xs:flex text-white ' onClick={()=>opensidebar()}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                    </svg>

        </div>
            <div className='xs:px-4 lg:px-0 ' id="mdsp">
                    <ul className='z-40 bg-white lg:w-1/5 xs:w-4/5 h-auto px-2 absolute flex flex-col py-4' id="">
                                <li className='w-full h-auto py-4 bg-green-500 text-white text-xl  text-center  '>
                                    <span className='align-middle'>قائمة المواد</span>
                                </li>
                        {
                            modules.length && modules.map((m,index)=>
                                <li 
                                    onClick={() => {window.location.href=`/modsp/${m.id}/${id}`}}
                                    className='w-full h-10 border border-gray-100 cursor-pointer' >
                                    <p className='text-md font-bold px-4 align-middle py-2'>{m.module}</p>
                                </li>
                            )
                        }
                    </ul>
            </div>
            
        </div>
  )
}

export default ModulesBySp