import React, {useState, useRef } from 'react'
import { Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import Nav from './Nav'
const SingUpForm = () =>{
    const history = useNavigate()
    const [nom, setNom] = useState("")
    const [prenom, setPrenom] = useState("")
    const [phone, setPhone] = useState("")
    const [wilaya, setWilaya] = useState("")
    const [password, setPassword] = useState("")

    let newUser =  {
        nom:nom,
        prenom:prenom,
        phone: phone,
        wilaya: wilaya,
        password: password
      }

    const urlAPI = `${process.env.REACT_APP_url}/user/register.php?client=${JSON.stringify(newUser)}`

    const NewUser = () =>{
        if(nom == "" || prenom == "" || phone == "" ||  wilaya == "" || password == ""){
            //inputMail.current.className = inputMail.current.className + " bg-red-400"
            alert("voulez-vous saisie vos coordonnées")
        }else{
            axios.get(urlAPI).then((res) => {
                history.push('/')
              });
        }
        //console.log(inputMail.current.className)
        
        //inputMail.current.focus()
        /*
        axios.get(urlAPI).then((res) => {
            history.push('/profile')
          });
          */
    }
  return (
    <div className='w-full h-screen flex flex-col ' id="loginpage">
        <Nav />
        <div className='w-full h-full flex justify-center items-center px-4'>
                <div className='lg:w-1/3 xs:w-full h-auto  flex flex-col bg-white shaddow-md border border-gray-400 shaddow-md rounded-md px-4 py-4 items-center justify-center'>
                    <span className='text-xl font-bold text-green-600'>مرحباً بك، سعيد برؤيتك.</span>

                    <div className="w-full h-auto flex flex-col mb-2">
                        <label htmlFor="username" className=''>اسم:</label>
                        <input type="text" id="username" name="username" value={nom} onChange={(e)=>setNom(e.target.value)}/>
                    </div>

                    <div className="w-full h-auto flex flex-col mb-2">
                        <label htmlFor="username" className=''>اللقب:</label>
                        <input type="text" id="username" name="username" value={prenom} onChange={(e)=>setPrenom(e.target.value)}/>
                    </div>

                    <div className="w-full h-auto flex flex-col mb-2">
                        <label htmlFor="username" className=''>رقم الهاتف :</label>
                        <input type="text" id="username" name="username" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                    </div>

                    <div className="w-full h-auto flex flex-col mb-2">
                        <label htmlFor="username" className=''>الولاية:</label>
                        <input type="text" id="username" name="username" value={wilaya} onChange={(e)=>setWilaya(e.target.value)}/>
                    </div>


                    <div className="w-full h-auto flex flex-col mb-2">
                        <label htmlFor="username">كلمة السر :</label>
                        <input type="text" id="username" name="username" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                    </div>
                    <button  onClick={()=>NewUser()} className='w-full h-12 bg-green-500 shaddow-lg text-white rounded-md'>تسجيل</button>
                </div>
        </div>
        
    </div>
  )
}

export default SingUpForm