import React from 'react'
import Nav from '../components/Nav'
import Pdffile from '../components/Pdffile'
import Specialite from '../components/Specialite'

const pdf = () =>{
  return (
    <div className='w-full'>
            <Nav />
            <Pdffile />
    </div>
  ) 
}

export default pdf