import React from 'react'
import Nav from '../components/Nav'
import Details from '../components/Details'

const details =() =>{
  return (
    <div className='w-full'>
            <Nav />
            <Details />
    </div>
  )
}

export default details