import React, { useEffect, useState } from 'react'
import axios from 'axios';

const Chapter = () =>{
  const [formation, setFormation] = useState([])
  const [chapitre, setChapitre] = useState("")
  const [media, setMedia] = useState("")

  const [fm, setFm] = useState("")

    const urlAPIFORMATION = `${process.env.REACT_APP_url}/formation/allf`
    useEffect(() => {
      axios.get(urlAPIFORMATION).then(res => { 
        setFormation(res.data)
      //setLoad(true)
      
      }).catch(err => {
      console.log(err)
      })
  }, [])

  const getimagename = (e)=>{
    setMedia(e)
  }

  const urlAddchap = `${process.env.REACT_APP_url}/chapter/newchapter.php?
                                                                          ch=${chapitre}
                                                                          &fm=${fm}
                                                                          &md=${media}`

  return (
    <div className='w-full h-auto bg-white border border-gray-100 rounded-md flex flex-row items-center justify-center py-4'>
                      <form className='w-1/2 px-4' method="post" encType="multipart/form-data" action={urlAddchap} id="mform" target="votar">
                      
                      <div className='w-full my-2'>
                          <label for="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">عنوان المحور</label>
                          <input
                                value={chapitre}
                                onChange={(e)=>setChapitre(e.target.value)} 
                                type="text" id="default-input" className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          </input>
                      </div>

                      <div className='w-full my-2'>
                          <label for="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">الدورات المتاحة</label>
                          <select 
                                value={fm}
                                onChange={(e)=>setFm(e.target.value)}  
                                id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              <option>الدورات </option>
                              {
                                formation.length && formation.map((forma,index)=>
                                    <option value={forma.idf}>
                                      {
                                        forma.name 
                                      }
                                    </option>
                                )
                                    }
                              
                          </select>
                      </div>

                      <div className='w-full my-2'>
                          <label for="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">فيديو</label>
                          <input 
                                type="file" 
                                id="pdfthumb" 
                                name='media'
                                accept='video/*'
                                onChange={(e)=>getimagename(e.target.files[0].name)} 
                                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          </input>
                      </div>


                      <button 
                            type="submit" 
                            //onClick={()=>uploadpdf()}
                            class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">أضف</button>
                   
                      </form>
              </div>
  )
}

export default Chapter