import React, {useEffect, useState }  from 'react'
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from "axios"

const Pdffile = () =>{
    const {id} = useParams(); 
    const [exam, setExam] = useState([])
    const [pdf, setPdf] = useState("")
    const [idv, setIdv] = useState("")

    const [isload, setIsLoad] = useState(false)
    const urlAPI = `${process.env.REACT_APP_url}/exams/exam.php?id=${id}`
    
    
   
    useEffect(() => {
        axios.get(urlAPI).then(res => { 
        setExam(res.data)
        setIdv(res.data[0].id)
        setPdf(res.data[0].pdf)
        //setIdv(res.data[0].videoex)
        //setLoad(true) 
        
        
        }).catch(err => {
        console.log(err)
        })
    }, [])
   
    

    

    
    const docs = [
      { uri: `${process.env.REACT_APP_url}/exams/pdf/${pdf}`,
        fileType:"application/pdf",
        fileName:"file.pdf"
     }
    //`${process.env.REACT_APP_url}/exams/pdf/${pdf}`
      //{ uri: require("../images/file.pdf") } // Local File
    ];
    
    const attPdf = ()=>{
      let frame  = document.getElementById("pdfFram")
        frame.src = `${process.env.REACT_APP_url}/exams/pdf/${pdf}`
      //setIsLoad(true)
    }
    //attPdf()
   //src={`${process.env.REACT_APP_url}/exams/pdf/${pdf}`}

   //const baseUrl = `https://aero.gomispro.com/api/exams/pdf/2.pdf`;
   
   const gotomedia =()=>{

   }
  return (
    <div className='w-full h-auto'>
      <div className='w-full h-20 bg-green-500 flex flex-row items-center justify-center px-12 mb-4'>
          <span className='lg:text-md  xs:text-sm font-semibold mx-4'>
          يمكنكم الآن متابعة تصحيح الإمتحان عبر الفيديو التالي .
          </span>
          <button  
          onClick={() => {window.location.href=`/examedia/${idv}`}}
          className='w-48 h-12 flex flex-row bg-yellow-400 rounded-md text-black items-center justify-center shadow-xl px-2 shadow-blue-500/50'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
           </svg>
           <span className='text-lg font-bold mx-4'>مشاهدة</span>

          </button>
      </div>
      <div className='w-full h-auto  flex flex-col items-center'>
      
        
        <iframe src={`${process.env.REACT_APP_url}/exams/pdf/${pdf}`} className='lg:w-4/5 xs:w-full h-screen'>

        </iframe>

        
        
        
        
      </div>
        
    </div>
  )
}

export default Pdffile