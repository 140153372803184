import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Chapter from './admin/Chapter';

const Details = () =>{
    const {id} = useParams();  
    const [formation, setFormation] = useState([])
    const [items, setItems] = useState([])
    
    const urlAPI = `${process.env.REACT_APP_url}/formation/formation.php?id=${id}`
    useEffect(() => {
        axios.get(urlAPI).then(res => { 
        setFormation(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])

    const urlAPIitems = `${process.env.REACT_APP_url}/chapter/items.php?id=${id}`
    useEffect(() => {
        axios.get(urlAPIitems).then(res => { 
        setItems(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])
    console.log("items")
    console.log(items)

  return (
    <div className='my-4'>
            <div className='w-full h-auto flex flex-col  min-h-screen mt-10'>
                    <div className='w-full h-20 bg-white px-12 bg-green-500 py-4  align-middle'>
                            <Link >
                                <span className='font-bold text-2xl text-white'>
                                        عرض محتويات الدورة التكوينية
                                </span>
                            </Link>
                    </div>
                    {
                        formation.length && formation.map((f, index)=>
                        
                        <div className='w-full h-auto  px-4 py-4 flex lg:flex-row xs:flex-col'>
                        <div className='lg:w-1/3 xs:w-full h-full '>
                                     <img className="h-96 w-96" src={`${process.env.REACT_APP_url}/formation/thumbnail/${f.image}`}/>
                        </div>

                        <div className='lg:w-2/3 xs:-full h-full '>
                                     
                                    <div className='w-full h-auto mb-2 py-4 border-b '>
                                        <span className='text-3xl font-bold  mx-4 text-green-700'>الدورة التكوينية</span>
                                        <span className='text-3xl font-bold  mx-4'>{f.name}</span>
                                    </div>
                                     <div className='w-full h-auto py-4 border-b border-green-400'>
                                        <p className='text-xl font-bold  mx-4'>معلومات عن الدورة:</p>
                                        <p className='text-md  px-4'>
                                                                إن قدرة الكائنات الحية في الوصول إلى درجة عالية من التنظيم و المحافظة على
                                                                و ADN النمط الظاهري للنوع تنشأ من المعلومات الوراثية الموجودة في جزيئة ال
                                                                التي تحدد نوع البروتينات لتشكل هذه الأخيرة أحد المكونات الأساسية لأجسام الكائنات الحية من المنظور الوظيفي حيث تؤمن وظائف خلاياها و نسجها. سنتعرض في هذا المجال لبعض الأسئلة الأساسية المتعلقة بالتخصص الوظيفي لهذه البروتينات
                                         </p>
                                     </div>

                                     <div className='w-full h-auto mb-2 py-2'>
                                        <span className='text-xl font-bold  mx-4'>المادة</span>
                                        <span className='text-xl font-bold  mx-4'>{f.module}</span>
                                    </div>
                                    <div className='w-full h-auto mb-2 py-2'>
                                        <span className='text-md font-bold  mx-4'>الشعبة</span>
                                        <span className='text-md font-bold  mx-4'>{f.label}</span>
                                    </div>
                                    <div className='w-full h-auto mt-4'>
                                        <button className='w-32 h-12 rounded-md bg-green-500 text-white shadow-md'>شراء الدورة</button>
                                    </div>

                        </div>

                        
                </div>
                        )
                    }

                <div className='w-full h-auto flex flex-row flex-wrap justify-between overflow-hidden px-8 py-8'>
                    <div className='lg:w-2/3 xs:w-full h-auto flex flex-col'>
                        <ul className='w-full h-auto flex flex-col'>
                            <div className='w-full h-16 text-white bg-green-400 px-4 text-xl font-bold flex items-center'>
                            <span className='align-middle'>معلومات عن الدورة:</span>
                            </div>
                            {
                                items.length && items.map((item, index)=>
                                    <div  onClick={() => {window.location.href=`/view/${item.idchap}`}}  
                                          className='w-full h-16 flex flex-row items-center my bg-white border cursor-pointer hover:bg-green-400 relative px-4'>
                                        <span className='lg:text-lg xs:text-md  my-2'>{item.title}</span>
                                        <span className='lg:text-lg xs:text-md  my-2 mx-4'>{item.name}</span>
                                        
                                        <span className='absolute left-4'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                                            </svg>

                                        </span>
                                    </div>
                                )
                            }
                        </ul>

                    </div>

                

                </div>
            </div>
    </div>
  )
}

export default Details