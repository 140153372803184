import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './cartCounter';
import totalReducer from './totalInCart';
import qttReducer from './quantity';

export default configureStore({
    reducer:{
        cartCounter: counterReducer,
        totalInCart : totalReducer,
        quantity: qttReducer
    }
})