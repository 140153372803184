import React, {useEffect, useState }  from 'react'

import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from "axios"

const Sujet = () =>{
    const [sujet, setSujet] = useState([])
    const {id} = useParams();  
    const urlAPI = `${process.env.REACT_APP_url}/sujet/sujet.php?id=${id}` ///http://aero.gomispro.com/api
    useEffect(() => {
        axios.get(urlAPI).then(res => { 
        setSujet(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])
    
  return (
    <div className='w-full h-auto flex flex-row '>
           {
            sujet.length && sujet.map((sj, index)=>
                <div 
                onClick={() => {window.location.href=`/pdf/${sj.id}`}}
                className='w-1/4 h-72 bg-gray-100 border-gray-100 rounded-md  mx-2 flex flex-col overflow-hidden cursor-pointer'>
                        <div className='w-full h-56 bg-gray-200'>
                        <img src={`${process.env.REACT_APP_url}/exams/thumbnail/${sj.thumbnail}`} className='h-full w-full'/> 
                        </div>
                        <div className='w-full h-auto flex flex-col px-4 py-2'>
                            <span className=''>{sj.subject}</span>
                            <span className=''>{sj.module}</span>
                        </div>
                </div>
            )
           }
    </div>
  )
}

export default Sujet