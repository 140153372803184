import React from 'react'
import Nav from '../components/Nav'
import AllFormation from '../components/AllFormation'
import Formation from '../components/Formation'

const tutorial = () =>{
  return (
    <div className='w-full'>
        <Nav />
        <Formation />
    </div>
  )
}

export default tutorial