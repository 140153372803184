import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Specialite =() =>{

    const [specialite, setSpecialite] = useState([])
    const urlAPIsp = `${process.env.REACT_APP_url}/specialite/allsp.php`
     useEffect(() => {
        axios.get(urlAPIsp).then(res => { 
        setSpecialite(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])
  return (
    <div className='w-full h-auto  flex flex-col px-8 py-4 bg-white '>
        <div className='w-32 h-auto px-2 py-2 bg-red-500 text-center rounded-md mb-2'>
                <span className='font-bold text-xl py-2'>التخصصات</span> 
        </div>
        <div className='w-full h-auto flex flex-row flex-wrap justify-between overflow-hidden'>
        {
                                specialite.length && specialite.map((sp,index)=>
                                <div 
                                onClick={() => {window.location.href=`/sp/${sp.id}`}}
                                className='w-48 h-16 border bg-gray-100 border-gray-100 rounded-md flex flex-col overflow-hidden  justify-center cursor-pointer hover:bg-yellow-400'>
                                    <div to={`sp/${sp.id}`} className='flex justify-center items-center'>
                                        <span className='text-md font-bold'>{sp.label}</span>
                                    </div>    
                                </div>
                                )
                              }
        </div>
    </div>
  )
}

export default Specialite