import React from 'react'

const Box3 = () =>{
  return (
    <div className='w-full h-screen flex lg:flex-row  xs:flex-col'>
                <div className='lg:w-1/2 h-full' id="boxImage3">

                </div>
                <div className='lg:w-1/2 h-full px-8 text-center py-4 flex flex-col items-center justify-center bg-green-900 text-white'>
                    
                    <span className='text-xl font-bold'>
                    يتمثل دور المرشد أحيانًا في حل مشكلة صغيرة جدًا ، ولكنها مشكلة يصنعها الطالب كثيرًا. نحن نسمح للمضي قدمًا 
                    </span>
                    <span>
                        مستقل ، ولكن لست وحيدا!
                    </span>
                    <ul>
                            <li className='text-xl'>في emelycad ، يمكنك الاعتماد على العديد من الدعم:</li>
                            <li className='text-xl'>أساتذة وخبراء في المجال.</li>
                            <li className='text-xl'>مستشارون تربويون مكرسون لنجاحك.</li>
                            <li className='text-xl'>مجتمع من الطلاب.</li>
                    </ul>
                  
                </div>
                
    </div>
  )
}

export default Box3