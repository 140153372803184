import React from 'react'
import LoginForm from '../../components/admin/LoginForm'

const AdminLog = () =>{
  return (
    <div className='w-full h-screen flex items-center justify-center bg-gray-100'>
        <LoginForm />
    </div>
  )
}

export default AdminLog