import React from 'react'
import Nav from '../components/Nav'
import SingUpForm from '../components/SingUpForm'

const singup = () =>{
  return (
    <div className='w-full'>
        <SingUpForm />
    </div>
  )
}

export default singup