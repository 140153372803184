import { createSlice } from '@reduxjs/toolkit'
let totalInCart = localStorage.getItem("totalincart");
let tic // total in cart
if(totalInCart == null){
    tic = 0;
}else{
    tic = parseFloat(JSON.parse(totalInCart));
}
export const totalSlice = createSlice({
    name:"totalcounter",
    initialState:{
        total: tic
    },
    reducers:{
        addtoamount: (state, action)=>{
                state.total += action.payload;
                
        },
       
        deletamount: (state, action)=>{
            state.total -= action.payload
        }
    }
});

export const {addtoamount, deletamount} = totalSlice.actions;
export default totalSlice.reducer;