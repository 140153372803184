import React from 'react'
import Nav from './Nav'

const LoginForm =()=> {
  return (
    <div className='w-full h-screen flex flex-col ' id="loginpage">
        <Nav />
        <div className='w-full h-full flex justify-center items-center px-4'>
                <div className='lg:w-1/3 xs:w-full h-96 flex flex-col bg-white shaddow-md border border-gray-400 shaddow-md rounded-md px-4 py-4 items-center justify-center'>
                    <span className='text-xl font-bold text-green-600'>مرحباً بك، سعيد برؤيتك.</span>
                    <div className="w-full h-auto flex flex-col mb-2">
                        <label htmlFor="username" className=''>رقم الهاتف :</label>
                        <input type="text" id="username" name="username" />
                    </div>
                    <div className="w-full h-auto flex flex-col mb-2">
                        <label htmlFor="username">كلمة السر :</label>
                        <input type="text" id="username" name="username" />
                    </div>
                    <button className='w-full h-12 bg-green-500 shaddow-lg text-white rounded-md'>تسجيل الدخول</button>
                </div>
        </div>
        
    </div>
  )
}

export default LoginForm