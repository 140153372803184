import React from 'react'
import View from '../components/View'
import Nav from '../components/Nav'

const courseview = () =>{
  return (
    <div className='w-full'>
            <Nav />
            <View />
    </div>
  )
}

export default courseview